<template lang="pug">
include ../../configs/template
div
  div.w-100.d-flex.flex-wrap.row
    div.col-12
      v-radio-group(v-model="body.userprofile.main_group")
        v-radio(
          v-for="group in listPermissionGroups"
          :key="group.id"
          :label="group.text"
          :value="group.text"
          hide-details)
    div.col-sm-12.col-md-4
      +field('body.last_name_eng','"lastNameEN"')
    div.col-sm-12.col-md-4
      +field('body.first_name_eng','"firstNameEN"')
    div(v-if="TYPE_USER.SECRETARY_ATC === body.userprofile.main_group").col-sm-12.col-md-4
      +select-validation('body.secretary_atc_profile.profile_type', 'listInstitutionType', 'accessSubscription', 'nameLang', '["required"]')(:readonly="isOnlyRead")
    div(v-if="[TYPE_USER.HEAD_CREWING, TYPE_USER.MANAGER_CREWING].includes(body.userprofile.main_group)").col-sm-12.col-md-4
      +field('body.email','"email"')
    div.col-sm-6.px-0
      div.w-100.d-flex.align-center
        PhoneNumberField(
          v-model="phoneNumber"
          :error-messages="$valid.validation($v.phoneNumber, ['minLength', 'maxLength'])").mb-6.px-0
        v-btn(icon @click="setContact")
          v-icon(color="success") mdi-plus
      div(v-if="phoneNumbersList.length").w-100
        div(v-for="(phone, index) in phoneNumbersList" :key="index").d-flex.align-center
          span {{ phone.value }}
          v-btn(color="error" icon @click="deleteContact(phone)")
            v-icon mdi-trash-can-outline

    div(v-if="sailorDocument.type_user === 'morrichservice'").col-12
      +select('body.userprofile.branch_office', 'listBranchOffice','affiliate', 'nameLang')(item-value="name_ukr")
    div.col-12
      FileDropZone(ref="mediaContent")
    div.col-12.mt-3
      v-btn(:loading="isLoading" color="success" @click="updateUserInfo") {{ $t('save') }}
</template>

<script>
// import { postFiles } from '@/mixins/main'
import { mapState, mapActions, mapGetters } from 'vuex'
import { setTransliteration } from '@/plugins/transliteration'
import { SUCCESS_CODE, TYPE_ACCESS_OF_SYSTEM, TYPE_USER } from '@/configs/constants'
import { accountManagement } from '@/mixins/validationRules'
import { clearBody } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
export default {
  name: 'AccountManagementEdit',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    PhoneNumberField: () => import('@/components/atoms/PhoneNumberField')
  },
  props: {
    sailorDocument: { type: Object, default: () => {} }
  },
  data () {
    return {
      files: [],
      isLoading: false,
      phoneNumber: '',
      checkAccess,
      body: {
        email: null,
        first_name_eng: null,
        last_name_eng: null,
        is_totp_enabled: null,
        is_u2f_enabled: null,
        userprofile: {
          main_group: null,
          branch_office: null
        },
        secretary_atc_profile: {
          profile_type: null
        }
      },
      TYPE_USER,
      isOnlyRead: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      listPermissionGroups: state => state.directory.registrationPermissionsList,
      listBranchOffice: state => state.directory.affiliate,
      listInstitutionType: state => state.directory.institutionTypes.filter(type => type.id !== TYPE_ACCESS_OF_SYSTEM.PLUSE_PAID)
    }),
    ...mapGetters(['doctorsByMedInstitutionID', 'regionById', 'institutionByType', 'getDirectoryObject']),

    documentID () {
      return this.$route.params.documentID
    },
    phoneNumbersList () {
      return this.sailorDocument?.userprofile.contact_info.filter(item => item.type_contact === 1) || []
    }
  },
  mounted () {
    this.body.secretary_atc_profile.profile_type = this.sailorDocument.secretary_atc_profile?.profile_type || null
    this.body.email = this.sailorDocument.email
    this.body.is_totp_enabled = this.sailorDocument.is_totp_enabled
    this.body.is_u2f_enabled = this.sailorDocument.is_u2f_enabled
    this.body.userprofile.main_group = this.sailorDocument.userprofile.main_group
    this.body.userprofile.branch_office = this.sailorDocument.userprofile.branch_office
    this.body.first_name_eng = this.sailorDocument.first_name_eng || setTransliteration(this.sailorDocument.first_name)
    this.body.last_name_eng = this.sailorDocument.last_name_eng || setTransliteration(this.sailorDocument.last_name)
    if (this.sailorDocument.userprofile.main_group === TYPE_USER.SECRETARY_ATC) this.checkInstitutionAccessType(this.sailorDocument.userprofile.education_institution)
  },
  validations () { return accountManagement(this) },
  methods: {
    ...mapActions(['updateUser', 'getAllUsers', 'addUserContact', 'deleteUserContact']),
    checkInstitutionAccessType (id) {
      if (this.getDirectoryObject({ value: 'institution', id })?.access_type === TYPE_ACCESS_OF_SYSTEM.FREE) {
        this.body.secretary_atc_profile.profile_type = TYPE_ACCESS_OF_SYSTEM.FREE
        this.isOnlyRead = true
      } else {
        !this.sailorDocument.secretary_atc_profile && (this.body.secretary_atc_profile.profile_type = null)
        this.isOnlyRead = false
      }
    },
    async updateUserInfo () {
      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) },
        media: {
          files: [...this.files, ...this.$refs.mediaContent.filesArray],
          name: 'UserProfile',
          photo_attr: 'order_scan'
        }
      }
      this.isLoading = true
      const response = await this.updateUser(data)
      if (response) {
        this.$notification.success('editedUser')
        this.$emit('loadData')
      //   if (this.files.length) {
      //     const fileResponse = await postFiles(this.files, 'UserProfile', this.sailorDocument.userprofile.id, 'order_scan')
      //     if ([200, 201].includes(fileResponse?.code)) this.$emit('loadData')
      //     else this.$notification.error('errorAddFile')
      //   } else this.$emit('loadData')
      }
      this.isLoading = false
    },

    async setContact () {
      if (this.$v.$invalid) return this.$v.$touch()
      const confirmation = await this.$swal({
        icon: 'info',
        title: `${this.$t('Do you really want save contact')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      })
      if (!confirmation) return false

      const body = { type_contact: 1, value: this.phoneNumber }
      const response = await this.addUserContact({ id: this.documentID, body })
      if (SUCCESS_CODE.includes(response.code)) {
        this.phoneNumber = ''
        this.sailorDocument.userprofile.contact_info.push(response.data)
        this.$notification.success('setSailorContact')
      }
    },

    async deleteContact (contact) {
      const confirmation = await this.$swal({
        icon: 'info',
        title: `${this.$t('Do you really want delete contact')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      })
      if (!confirmation) return false

      const response = await this.deleteUserContact({ id: this.documentID, contactID: contact.id })
      if (SUCCESS_CODE.includes(response.code)) {
        this.sailorDocument.userprofile.contact_info = this.sailorDocument.userprofile.contact_info
          .filter(item => item.id !== contact.id)
        this.$notification.success('deleteSailorContact')
      }
    }
  }
}
</script>
