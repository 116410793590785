let letters = []

export const setTransliteration = (str, model, saveEN) => {
  if (str) {
    letters = str.split('')

    let strEng = letters.reduce((result, letter, index) => {
      result += transliteration(letter, index)
      return result || ''
    }, '')
    return strEng
  } else return null
}

export const transliteration = (letter, index) => {
  // Switch case cause of ID.GOV ua sent full name in upper case
  if (index === 0 || letters[index - 1] === ' ') letter = letter.toUpperCase()
  else letter = letter.toLowerCase()

  switch (letter) {
    case 'а': return 'a'
    case 'А': return 'A'

    case 'б': return 'b'
    case 'Б': return 'B'

    case 'в': return 'v'
    case 'В': return 'V'

    case 'г': return 'h'
    case 'Г': return 'H'

    case 'ґ': return 'g'
    case 'Ґ': return 'G'

    case 'д': return 'd'
    case 'Д': return 'D'

    case 'е': return 'e'
    case 'Е': return 'E'

    case 'є': return 'ie'
    case 'Є': return 'Ye'

    case 'ж': return 'zh'
    case 'Ж': return 'Zh'

    case 'з': return 'z'
    case 'З': return 'Z'

    case 'и': return 'y'
    case 'И': return 'Y'

    case 'і': return 'i'
    case 'І': return 'I'

    case 'ї': return 'i'
    case 'Ї': return 'Yi'

    case 'й': return 'i'
    case 'Й': return 'Y'

    case 'к': return 'k'
    case 'К': return 'K'

    case 'л': return 'l'
    case 'Л': return 'L'

    case 'м': return 'm'
    case 'М': return 'M'

    case 'н': return 'n'
    case 'Н': return 'N'

    case 'о': return 'o'
    case 'О': return 'O'

    case 'п': return 'p'
    case 'П': return 'P'

    case 'р': return 'r'
    case 'Р': return 'R'

    case 'с': return 's'
    case 'С': return 'S'

    case 'т': return 't'
    case 'Т': return 'T'

    case 'у': return 'u'
    case 'У': return 'U'

    case 'ф': return 'f'
    case 'Ф': return 'F'

    case 'х': return 'kh'
    case 'Х': return 'Kh'

    case 'ц': return 'ts'
    case 'Ц': return 'Ts'

    case 'ч': return 'ch'
    case 'Ч': return 'Ch'

    case 'ш': return 'sh'
    case 'Ш': return 'Sh'

    case 'щ': return 'shch'
    case 'Щ': return 'Shch'

    case 'ь': return ''
    case 'Ь': return ''

    case 'ю': return 'iu'
    case 'Ю': return 'Yu'

    case 'я': return 'ia'
    case 'Я': return 'Ya'

    case '\'': return ''
    // case ' ': return ' '
    // case '-': return '-'
    default: return letter
  }
}
